import request from '@/utils/request'
import Qs from 'qs'



export function save(params) {
	return request({
    url: '/frontend/website/members-jjr/save',
		method: 'post',
		data:Qs.stringify(params)
	})
}


export function detail(params) {
	return request({
    url: '/frontend/website/members-jjr/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}

